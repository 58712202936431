.coms{
    color:lightgrey !important;
    display:flex;
    justify-content: center;
}
.appleIcon{
    padding-bottom: .5rem !important;
}
.footer-container {
    width: 100%;
    border: none !important;
    display: flex;
    justify-content: space-between;
}
.footer-info-text h3 {
    color: #fff;
    font-size: 16px;
}
.columns {
    width: 33%;

}
.right-side0sec-on-footer  {
    border: none !important;
    /* text-align: center; */
}
.links-download {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.links-download a img {
    margin: 0 !important;
    width: 180px !important;
    height: 90px !important;
    /* border: 1px solid red; */
    padding: 0 !important;
}
.links-download img {
    margin: 0 !important;
    width: 180px;
    height: 90px !important;
    padding: 0 !important;

}
.followus {
   width: 200px;
   height: 200px;
   /* border: 1px solid red; */
}
.followus img {
    width: 100%;
    height: 100%;
}
.footer-text-text {
    width: auto !important;
}
.footer-sec-section  ul li{
    margin: 13px 0px !important;
}
.footer-social-link h3, .section-heading-use h3 {
    color: #fff;
}
.footer-social-link {
    text-align: center;
}
.footer-social-link ul{
    list-style: none;
    display: flex;
   padding: 0px;
   justify-content: center;
}
.footer-sec-section ul {
    list-style: none;
    padding-left: 0px;
}

.footer-social-link ul li {
   width: 30px;
   height: 30px;
   padding-top: 2px;
   text-align: center;
   border-radius: 50%;
   margin: 0 0 0 10px;
   background-color: black;
   
}
.footer-info-text, .footer-text-text {
    margin: 0px !important;
}
.footer-social-link ul li:first-child {
    margin:0px;
}
.border-line {
    padding-left: 6% !important;
    border-left: 1px solid rgba(128, 128, 128, 0.564);
    border-right: 1px solid rgba(128, 128, 128, 0.564);
}
@media (max-width: 992px) {
    .border-line {
        border-right: none;
        padding-left: 0px;
    }
}
@media (max-width: 768px) {
    .border-line {
        border: none
    }
}
    
@media only screen and (max-width: 768px) and (min-width: 320px) {
    .columns {
        width: 100%;
    }
}