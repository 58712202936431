.Drawer_Container {
	height: 100vh;
	width: 20%;
	background-color: white;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
	background-color: rgba(0, 60, 126, 1);
	opacity: 1;
	overflow-y: auto;
	overflow-x: hidden;

}

.Drawer_Container::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}
.DrawerContents__Container {
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 20px;
}

.DrawerContent__Header {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 10px;
}

.DrawerContent__InnerHeader {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding-top: 10px;
}

.DrawerContent_Logo {
	width: 150px;
	height: 40%;
}

.DrawerContent__Lists {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.DrawerContent__Links {
	text-decoration: none;
	cursor: pointer;
}

.DrawerContent__Category {
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 10px;
	text-decoration: none;
}

.DrawerContent__HeaderContact {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
}

.DrawerContent__HeaderContactList {
	color: #fff !important;
	display: flex;
	align-items: center;
	gap: 10px;
}

.rotate {
	transform: rotate(180deg);
}
.slideInRight {
	-webkit-animation-name: slideInRight;
	animation-name: slideInRight;
	-webkit-animation-duration: .9s;
	animation-duration: 0.9s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
@-webkit-keyframes slideInRight {
	0% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		visibility: visible;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
@keyframes slideInRight {
	0% {
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		visibility: visible;
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}

.slideOutRight {
	-webkit-animation-name: slideOutRight;
	animation-name: slideOutRight;
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
@-webkit-keyframes slideOutRight {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		visibility: hidden;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
}
@keyframes slideOutRight {
	0% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	100% {
		visibility: hidden;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
	}
}

.slideInDown {
	-webkit-animation-name: slideInDown;
	animation-name: slideInDown;
	-webkit-animation-duration: .5s;
	animation-duration: .5s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
@-webkit-keyframes slideInDown {
	0% {
		-webkit-transform: translateY(-40%);
		transform: translateY(-40%);
		visibility: visible;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}
@keyframes slideInDown {
	0% {
		-webkit-transform: translateY(-40%);
		transform: translateY(-40%);
		visibility: visible;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

.DrawerHeading_Welcome {
	color: #4fb68d;
	font-size: 30px;
	font-weight: bold;
	text-align: center;
}

.Drawer_User_Data_Container {
	margin-top: 25px;
	display: flex;
	flex: 1;
	/* justify-content: space-evenly; */
}

.Drawer_Inner_Content {
	width: 100%;
}

.Drawer_Logout {
	cursor: pointer;
}

.Drawer_Other_Category {
	color: white;
	margin-bottom: unset;
}

.Drawer_Divider_line {
	color: white;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.Drawer_User_Img img {
	width: 70px;
	outline: 3px solid #4fb68d;
	border-radius: 50%;
	margin-right: 15px;
}

.Drawer_Orbitmart_Logo {
	display: flex;
	justify-content: center;
}

.Drawer_Orbitmart_Logo-1 {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.Drawer_Orbitmart_Logo img,
.Drawer_Orbitmart_Logo-1 img {
	width: 60px;
}

.Drawer_User_Data {
	color: white;
	width: 210px;
}

.Drawer_User_Data h5,
.Drawer_User_Data p {
	color: white;
	text-overflow: ellipsis;
	width: calc(90%);
	overflow: hidden;
}

.Drawer_User_Data h5 {
	font-size: 16px;
	text-overflow: ellipsis;
	width: calc(90%);
	overflow: hidden;
}

.Drawer_User_Data p {
	font-size: 15px;
}


.Drawer_logo{
	width: 100px;
	height:100px;
}

.Drawer_footer_logo{
	display: flex;
	justify-content: center;
	margin-bottom: 10px;
}
.DrawerContent__Category-indivisual-sec {
	/* background-color: #fff; */
	border: none;
	border-radius: 0px;
	border-bottom: 1px solid #fff;
}
.DrawerContent__Category-indivisual-sec .i, .DrawerContent__Category-indivisual-sec .Cont_span {
	color: #fff !important;
}
.DrawerContent__Category-indivisual-sec span {
	color: #fff !important;
	/* color: #003B95 !important; */

}
.DrawerContent__Category-content {
	padding: 3px 10px;
	/* border-radius: 4px; */
	width: 100%;
}
