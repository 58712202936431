.main-container-myOrder {
    margin-top: 90px;
}
.search-box .formInput {
    width: 100%;
}
.Search-Orders {
    width: 20% !important;
    padding: 0 20px;
}
.search-input-field {
    width: 80% !important;
}
.main-orders-carts {
    background-color: #fff !important;
    padding: 10px !important;
}
.order-containers {
    /* background-color: red !important; */
    border: 1px solid rgba(128, 128, 128, 0.221) !important;
    border-radius: 3px !important;
    margin: 10px 0  !important;
}
.some-padding-on-order-carts {
    border: none !important;
    padding-left: 0 !important;
}
@media (min-width: 320px) and (max-width: 520px)  {
    .Search-Orders {
        font-size: 11px !important;
        border: 1px solid red;
        padding: 0px;
    }
}