.Privacy_policy_container h4,
.Privacy_policy_container h5,
.Privacy_policy_container h6,
.Privacy_policy_container small {
	font-family: "Roboto", sans-serif;
}

.Privacy_policy_bolded_content {
	font-family: "Roboto", sans-serif;
}

.Privacy_policy_subheading_text {
	font-weight: 600;
}
