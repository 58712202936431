.d-flex {
  display: flex;
  align-items: center;
}
.pe-1 {
  padding-right: 0.4rem !important;
}
.ps-1 {
  padding-left: 0.4rem !important;
}

.pincd {
  color: #fff;
  /* padding: 2px; */
}
.navbar-nav {
  text-align: justify;

  min-width: 100%;
}
/* .bg-navy {
  background-color: rgba(0, 60, 126, 1);
} */
.dropdown-toggle {
  font-weight: unset;
  color: black;
}

.nav-item {
  /* margin: 2px auto ; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 2px;

  margin-bottom: 2px;
}
.cust-srch-input {
  width: 500px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px 100px 10px 20px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  font-size: 13px;
}
.fa-search {
  color: #003B95;
}

.cust-srch-btn {
  position: absolute;
  right: -5px;
  top: 0px;
  border: 0;
  /* background: #66cdaa; */
  background: transparent;
  /* color: #fff; */
  outline: none;
  margin: 0;
  padding: 3px 17px;
  border-radius: 0px 5px 5px 0px;
  z-index: 3;
  font-size: 16px;
  height: 42px;
}

/* .nav-link{
  color:black;
  padding: 5px;
  margin:5px;
} */
/* .dropdown{
  margin: 0;
  padding: 0;
  font-weight: normal;
} */
.res-text {
  display: flex;
  flex-direction: row;
  margin: 2px auto;
  /* margin-top: 80px; */
  /* text-align: center; */
  justify-content: space-between;
  /* min-width: 100%; */
  align-items: center;
  width: 50%;
  font-size: 30px;
  margin: 100px auto 20px auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.contactNav {
  display:"flex";
  align-items:"center";
  justify-content:"center";
  /* margin-left: 90px; */
}
.res-box {
  margin: 0;
  padding: 0;
  /* background-color: #f5f6fa; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown-item {
  margin-left: 1px;
  margin-right: 1px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.navitemsdrop {
  height: 48px;
  line-height: 2.4;
}
.contactNav, .dropdown-item {
  /* font-size: 18px; */
  font-weight: 500;
  /* border-bottom: 1px solid red; */
} 

.hamburger_icon_container {
  display: flex;
  align-items: center;
}

.hamburger_icon {
  color: #fff;
  font-size: 25px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}
.icons {
  padding: 3px 6px;
  border-radius: 4px;
}
.icons:hover {
  background-color: #fff;
}
.icons:hover small b, .icons:hover i, .icons:hover i sup {
  color: #003B95 !important;
}
.active {
  background-color: #fff !important;
  color: #003B95 !important; /* You can define other active styles here */
}

.active small b, .active i, .active i sup {
  color: #003B95 !important; /* Set active color */
}
/* .res-text{
  display: flex;
  flex-direction: row;
  background-color: #f5f6fa;
  text-align: justify;
  justify-content: center;
  min-width: 100%;
  align-items: center;
 
margin: auto;
margin-top: 80px;
} */
/* width:100vw;
 justify-content: center;
 align-items: center; */
/* .dropdown-toggle,.dropdown-item{
  padding: 0;
} */
/* .res-text .nav-link,.dropdown-toggle,.dropdown-item{
font-size:1rem;
padding:0.2rem auto;
}
.dropdown-menu .show {top:0%;position: absolute;} */
/* 
  calc(100vw -60vw- 20px); */
@media screen and (max-width: 1198px) {
  .bg-navy {
    background-color: white;
    /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset; */

    box-shadow: rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
      rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px,
      rgba(0, 0, 0, 0.09) 0px 32px 16px;
  }
  .text-white {
    color: rgba(0, 60, 126, 1) !important;
  }
  .cust-srch-btn {
    background: rgba(0, 60, 126, 1) !important;
  }

  .cust-srch-input {
    border: 1px solid rgb(32, 41, 110);
  }
  .nav-item {
    margin-left: 2px;
  }
}
@media screen and (max-width: 991px) {
  .cust-srch-input {
    width: 400px;
  }
  /* .res-text .nav-link,.dropdown-toggle,.dropdown-item{
    font-size:0.8rem
    } */
  .bg-navy {
    background-color: white;
  }
  .text-white {
    color: rgba(0, 60, 126, 1) !important;
  }
}
@media screen and (max-width: 461px) {
  .cust-srch-input {
    width: 300px;
  }
  /* .res-text .nav-link,.dropdown-toggle,.dropdown-item{
    font-size:0.6rem
    } */
}
@media screen and (max-width: 371px) {
  .cust-srch-input {
    width: 200px;
    font-size: 8.7px;
  }
  .cust-srch-btn {
    font-size: 10px;
    height: 35px;
  }
  /* .res-text .nav-link,.dropdown-toggle,.dropdown-item{
    font-size:0.5rem
    } */
}
@media screen and (max-width: 201px) {
  .cust-srch-input {
    width: 100px;
  }
  /* .res-text .nav-link,.dropdown-toggle,.dropdown-item{
    font-size:0.4rem
    } */
}
