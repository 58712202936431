.main-container-orderPlace {
    margin-top: 80px;
}
.orderPlace-border-less {
    border: none !important;
}
.btntract {
    background-color: #003B95 !important;
    color: #fff;
}
/* .btntract:hover {
    background-color: #fff !important;
    color: #003B95 !important;
} */

.shopingContiBtn  {
    background-color: #fff !important;
    color: #003B95 !important;
}

/* .shopingContiBtn:hover {
    color: #fff !important;
    background-color: #003B95 !important;
} */