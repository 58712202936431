.Terms_conditions_container h5,
.Terms_conditions_container h6,
.Terms_conditions_container small {
	font-family: "Roboto", sans-serif;
}

.General_terms_condition_heading {
	font-family: "Roboto", sans-serif;
	font-weight: 700;
}

.Terms_conditions_bolded_content {
	font-family: "Roboto", sans-serif;
}
h2{
	font-size: 20px;
	font-weight: 600;
}
.points-tc h2{
	margin-top: 40px;
}  
