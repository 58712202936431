* {
	box-sizing: border-box;
}

html, body {
    padding:0;
    margin:0;
}

body {
	color: #2c3e50;
	font-size: 1em;
	font-family: 'Source Serif Pro', 'Helvetica Neue', serif;
}

h2 {
	font-family: 'Source Sans Pro', sans-serif;
}

.questions{
  border: 1px solid rgb(179, 174, 174);
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
