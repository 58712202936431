@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;600&display=swap');

*{
  font-family: 'Poppins', sans-serif;
  margin:0; padding:0;
  box-sizing: border-box;
  outline: none; border:none; 
  text-transform: capitalize;
  transition: all .2s linear;
}

.container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding:25px;
  min-height: 100vh;
  /* background: linear-gradient(90deg, #ccc 60%, #ccc 40.1%); */
  padding-bottom: 70px;
}

.container form{
  padding:20px;
  width:700px;
  background: rgb(252, 251, 251);
  box-shadow: 0 5px 10px rgba(199, 189, 189, 0.89);
}
.container .product-Details {
  width: 100% !important;
}
.Product-Main-Heading {
  font-size: 24px;
  margin-bottom: 20px;
}
.prduct-head-item-size {
  font-size: 15px !important;
  font-weight: 500;
}
.product-description {
  color: #2e323c;
  font-weight: 400 !important;
}
.product-price {
  font-size: 18px;
  font-weight: 600;
}

.container form.row{
    display: flex;
    flex-wrap: wrap;
    gap:15px;
}
.container form.row.col{
 flex:240px;
  }
  .container form .row .col .title{
    font-size: 20px;
    color:#333;
    padding-bottom: 5px;
    text-transform: uppercase;
  }
  
  .container form .row .col .inputBox{
    margin:15px 0;
  }
  
  .container form .row .col .inputBox span{
    margin-bottom: 10px;
    display: block;
  }
  
  .container form .row .col .inputBox input{
    width: 100%;
    border:1px solid #ccc;
    padding:10px 15px;
    font-size: 15px;
    text-transform: none;
  }
  
  .container form .row .col .inputBox input:focus{
    border:1px solid #000;
  }
  
  .container form .row .col .flex{
    display: flex;
    gap:15px;
  }
  
  .container form .row .col .flex .inputBox{
    margin-top: 5px;
  }
  
  .container form .row .col .inputBox img{
    height: 40px;
    margin-top: 5px;
    filter: drop-shadow(0 0 1px #000);
  }
  
  .container form .submit-btn{
    width: 100%;
    padding:12px;
    font-size: 17px;
    background: #ccc;
    color:#fff;
    margin-top: 5px;
    cursor: pointer;
  }
  
  .container form .submit-btn:hover{
    background: #003c7e;
  }
  .abc{
    padding:20px;
    width:500px;
    background: rgb(252, 251, 251);
    box-shadow: 0 5px 10px rgba(7, 1, 1, 0.89);
  }
  .li{
    background-color: #fff;
    
    color: #000;
    display: block;
    font-style: black;
  }
  .li ul{
    position: absolute;
    background: #fff;
    display: none;

  }
  .a ul li{
    width: 100%;
    border-top: 1px solid rgba(0,0,0,.1);
  }
