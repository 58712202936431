@import url("https://fonts.googleapis.com/css2?family=Fira+Sans&family=Roboto+Slab:wght@100;300;600&display=swap");
.about_head {
  font-family: "Fira Sans", sans-serif;
  padding-top: 5rem;
  /* padding-left: 8rem; */
}

.about_us_container{
  padding: 1rem;
}
.pra-about-text {
  color: #475f7b;
}
.about_us_container:nth-child(1), .about_us_container:nth-child(3) {
  background-color: aliceblue;
}
.main-about-container {
  margin-top: 90px;
}
.boldText-para h6{
margin: 20px 0;
}
.about_us_text {
  width: 100%;
  font-family: "Fira Sans", sans-serif;
}

.About_us_main_heading {
  font-family: "Fira Sans", sans-serif;
  font-weight: 600;
}

.about_us_content {
  font-family: "Fira Sans", sans-serif;
  line-height: 1.5;
}

.about_img {
  width: 90%;
  margin: 2rem 0rem;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about_us_image{
	width: 100%;
	height:100%;
}

@media screen and (min-width: 992px) {
  .about_us_container {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }


.about_us_text {
	width: 49%;
  }

  .about_img {
	width: 49%;
  }
}
