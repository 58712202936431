.grid-container {
    display: grid;
    grid-template-columns: repeat(6,1fr);
    grid-gap: 10px;
    padding: 20px;
    width:100%;
  
  }
  .change-container::before{
    content: "";
    background-image: url(https://cdn-icons-png.flaticon.com/128/318/318276.png);
    background-size: cover;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    align-self: center;
  }
  .change-container::after{
    content: "";
    background-image: url(https://cdn-icons-png.flaticon.com/512/318/318275.png);
    background-size: cover;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
    
    align-self: center;
    
  
    
  }
  .grid-container > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding: 20px 5px 0 5px;
      }
      
  .domainico{
    font-size:2rem;
    background-color: rgb(19, 19, 137);
  
  }
  /* Increment Decrement */
  .id-icon{
  font-size:1.2rem;
  color: rgb(197, 123, 123);
  }
  .text-body{
  font-size:1rem
  }
  .text-body1{
  font-size:0.9rem
  }
  
  .product-container{
  display:flex;
  flex-direction: column;
  /* width:250px; */
  width: 230px;
  }
  .dright{
  margin-left: auto;
  }
  .text-b{
  font-weight: bolder;
  }
  /* @media screen and (max-width: 769px) {
  
  } */
  
  
  
  .flex-container {
  display: flex;
  }
  /* .flex-container::before{
  content:" <  ";
  height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
      background-color: #f1f1f1;
    align-self: center;
  }
  .flex-container::after{
  content:">";
  height: 20px;
    width: 20px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
  background-color: #f1f1f1;
    align-self: center;
  } */
  
  .flex-container > div {
  background-color: #f1f1f1;
  margin: 10px;
  padding: 20px;
  font-size: 30px;
  width:200px;
  height:300px;
  }
  
  .owl-prev, .owl-next {
  /* width: 15px;
  height: 100px; */
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-right: 5px;
  text-align: center;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  display: block !important;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  align-self: center;
  font-weight: bold;
  }
  
  /* .owl-prev,.owl-next{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
  background-color: #f1f1f1;
  align-self: center;
  text-align: center;
  
  } */
  
  .owl-prev { left: -35px; }
  .owl-next { right: -35px; }
  .owl-prev i, .owl-next i {transform : scale(2,5); color: #ccc;}
  /* .pre-next-icon{
  
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
    background-color: #f1f1f1;
  align-self: center;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }
  .owl-prev,.owl-next{
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;
  background-color: #f1f1f1;
  align-self: center;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
  }
  
  .owl-carousel .nav-btn{
  height: 47px;
  position: absolute;
  width: 26px;
  cursor: pointer;
  top: 100px !important;
  }
  
  .owl-carousel .owl-nav .owl-prev:before{
  
  
  margin-right:10px;
  }
  .owl-carousel .owl-nav .owl-next:after{
  
  
  margin-right:10px;
  } */
  
  .bg-gr{
  background-color: #027d09;
  color:white;
  padding: 2px auto;
  }
  .txt-bl{
  color: #003C7E;
  background-color: white;
  /* border: #003C7E 1px solid; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px , rgba(0, 0, 0, 0.3) 0px 1px 3px -1px inset;
  
  }
  .txt-or
  {
  color: #003C7E;
  }
  
  
  .mer-card {
  margin: 10px ;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 175px;
  height:200px;
  
  
  }
  .mer-card-header img {
  width: 100%;
  height: 80%;
  object-fit: fill;
  }
  .mer-card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 10px 10px 10px;
  min-height: 100px;
  }
  
  
  
  
  .mer-card-body p {
  font-size: 13px;
  margin: 0 0 40px;
  }
  .login-btn{
    
    cursor: pointer;
    color:white;
    padding-left: 0.5rem;
  
  }
  
  /* Popup style */
  .popup-box {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .box {
    position: relative;
    width: 50%;
    margin: 5rem auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    /* background: white; */
    border-radius: 4px;
    /* padding: 20px; */
    border: 1px solid #999;
    /* overflow: auto; */
   
  }
  
  .close-icon {
    content: 'x';
    cursor: pointer;
    position: fixed;
    right: calc(25% - 30px);
    /* top: calc(100vh - 85vh -33px); */
    /* background-color: #ededed; */
    /* background-color: white; */
    color: white;
    /* border: #003C7E 1px solid; */
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
    /* color: #003C7E; */
    background-color: #003C7E;
  }
  
  /* .bg-orbit{
    background-color: #003C7E;
    height:100%;
    width:40%;
    position:absolute;
    top:1px;
    left:1px;
    
  }
  .login-form{
    background-color: #003C7E;
    height:100%;
    width:auto;
    position:absolute;
    top:1px;
    left:1px;
    
  } */
  .login-btn{
    background-color:"#fb641b";color:"white";
  }
  .policy{
    font-size: 0.65rem;
    margin-top: 2rem;
   
  }
  .lk{
    font-size: 0.65rem;
    color:#0000ff !important;
    cursor:pointer;
  
  }
  
  /* input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: white !important;
    color: !important;
  } */
  
  
  .otpContainer{
    display:flex;
    gap:2rem;
    justify-content: center;
  
  
  }
  .otpInput{
    border:#999 1px solid;
    width:3rem;
    height:3rem;
    text-align: center;
  }
  .loader{
   
    display:flex;
    flex-direction: row;
   
  }
  .loading{
    width:20px;
    height:20px;
    margin:2px auto;
    padding:1px auto;
    background:#003C7E; 
    border-radius: 50%;
    animation: loadingAnimation 3s infinite;
  }
  @keyframes loadingAnimation {
    0% {
      opacity:0;
      transform:scale(0);
     
    }
    25%{
      opacity:1;
      transform:scale(1);
   
    }
    100% {
      opacity:0;
      transform:scale(0);
  
    }
   
  }
  
     @keyframes spinner {
          0% {
            transform: translate3d(-50%, -50%, 0) rotate(0deg);
          }
          100% {
            transform: translate3d(-50%, -50%, 0) rotate(360deg);
          }
        }
        .spin::before {
          animation: 1.5s linear infinite spinner;
          animation-play-state: inherit;
          border: solid 5px #cfd0d1;
          border-bottom-color: #003C7E;
          border-radius: 50%;
          content: "";
          height: 50px;
          width: 50px;
          position: absolute;
          top: 15vh;
          left: 25vw;
          transform: translate3d(-50%, -50%, 0);
          will-change: transform;
          
        }
  
  
        .icon-stack {
          position: relative;
          display: inline-block;
          width: 2em;
          height: 5em;
          line-height: 4em;
          vertical-align: middle;
        }
        .icon-stack-1x,
        .icon-stack-2x,
        .icon-stack-3x {
          position: absolute;
          left: 0;
          width: 100%;
          text-align: center;
        }
        .icon-stack-1x {
          line-height: inherit;
        }
        .icon-stack-2x {
          font-size: 1.5em;
        }
        .icon-stack-3x {
          font-size: 2em;
        }
    .right_align { padding-left:5px; }
      
  
    .title1:empty:not(:focus):before {
      content: attr(data-placeholder)
      
    }
  
    .owl-carousel .owl-stage-outer {position:relative !important;overflow:hidden !important}
  
    .owl-carousel .owl-wrapper-outer {width:100% !important;position:relative !important;overflow:visible !important}
  
  
  
    .loader-outer {
      display: flex;
      align-items: center;
      justify-content: center;
     height: 100vh;
   
      background: #fff;
    }
  
    .loader>span {
      width: 9px;
      height: 9px;
      display: block;
      background: #003C7E;
      border-radius: 50%;
      position: relative;
      margin: 0 5px;
    }
    
    .loader {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    .dot-1 {
      animation: anim 1s linear 0s infinite;
    }
    
    .dot-2 {
      animation: anim 1s linear 0.25s infinite;
    }
    
    .dot-3 {
      animation: anim 1s linear 0.50s infinite;
    }
    
    .dot-4 {
      animation: anim 1s linear 0.75s infinite;
    }
    
    @keyframes anim {
      0% {
        top: 0;
      }
    
      50% {
        top: 15px;
      }
    
      100% {
        top: 0;
      }
    } 
  
    .scroll-bar{
  
      overflow: auto;
      height:600px;
      /* border:1px solid #003C7E; */
    }
  
    /* width */
  ::-webkit-scrollbar {
    width: 10px;
    border:1px solid grey;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 5px;
  }
  
  
  .search-box{
    font-style: sans-serif;
    margin:25px auto;
    
  }
  .search-box form{
    width:50%;
  
  }
  .search-box form input{
    position: relative;
    display:inline-block;
    font-size: 15px;
    box-sizing: border-box;
    transition: .5s;
  }
  .search-box form input[type='text']{
  
    border:2px solid grey;
    width:75%;
    height:40px;
    border-radius: 5px 0 0 5px;
    padding:0 25px;
  }
  .search-box form input[type='submit']{
    
    position: relative;
    border-radius: 0 5px 5px 0;
    height:40px;
    width:25%;
    background:#003C7E;
    border:none;
    outline:none;
    cursor: pointer;
    color:white;
  }
  
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #20c997; 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a5a5a5; 
  }



  /* animated code */
.merchant-cart {
    cursor: pointer;
    /* width: 100%; */
}
.img-container {
    overflow: hidden;
}
/* .merchant-cart img {
    transition: 1s;
}
.merchant-cart img:hover {
    transform: scale(1.3);
    z-index: 2;
} */

.single-cart {
    margin: 20px 0;
    transition: 1s;
}
.merchant-cart:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    z-index: 2;
}

.single-cart:hover{
    transform: scale(1.2);
} 
.owl-dots {
    display: none !important;
}
.left-border {
  border: none !important;
}