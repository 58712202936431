#body {
  overflow: hidden;
}
.row {
  width: 100% !important;
}
.secbar{
  height: 50px;
  padding-bottom: 187px;
  margin-top: 12px;
  margin-bottom: -159px;
  margin-left: 70px;
  margin-right: 80px;
}
.mx-5 { 
  margin-top: 52px !important;
  margin-bottom: 52px !important;
}

#customeric {
  height: 3rem;
  margin-right: 10px;
  margin-top: 15px;
}

.shoppingbag i {  /* user icon*/ 
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  
}

span {
  font-size: 15px;
}
a {
  color: #fff;
  font-size: 15px;
  /* color: #0062cc; */
  /* border-bottom:2px solid #0062cc; */
}
.box-part {
  background: #fff;
  border-radius: 0;
  /* padding: 10px 10px; */
  margin: 10px 10px;
}
.text {
  margin: 10px 0px;
}

.fa {
  color: #4183d7;
}

.checked {
  color: orange;
}
#searchi {
  font-size: 20px;
  color: #fff;
}
.row.content {
  height: 1500px;
}
.sidenav {
  background-color: #f1f1f1;
  height: 100%;
}
@media screen and (max-width: 767px) {
  .sidenav {
    height: auto;
    padding: 15px;
  }
  .row.content {
    height: auto;
  }
}

#circle {
  width: 18px;
  height: 18px;
  background: rgb(104, 143, 104);
  border-radius: 50%;
  position: absolute;
  margin-left: 18px;
  margin-top: -18px;
}

#circle h5 {
  color: white;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#_desktop_contact_link {
  display: inline-block;
}

#_desktop_contact_link #contact-link {
  margin-top: 0.9375rem;
}

#_desktop_contact_link .contact-link {
  float: right;
  /* padding-left: 45px; */
  line-height: 17px;
  position: relative;
  color: #000;
  margin-right: 20px;
  text-transform: capitalize;
  /* margin-top: 6px; */
  /* margin-left: 70px; */
}

@media (max-width: 1199px) {
  #_desktop_contact_link .contact-link {
    margin-left: 30px;
  }
 
}

#_desktop_contact_link .contact-link:before {
  display: inline-block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 0;
  top: 0;
}

#_desktop_contact_link .contact-link a {
  color: #253237;
  /* font-weight: 500; */
}

.button1 {
  background-color: #4fb68d;
  color: #fff;
  margin: 20px 30px;
  text-decoration: none;
  outline: 2px solid;
}
.button1:hover {
  background-color: #4fb68d;
  color: black;
  text-decoration: none;
  outline: 2px solid;
}

.static_cms {
  position: relative;
  border: 2px solid #ebebeb;
  /* margin: 30px 50px; */
  border-radius: 5px;
  /* padding: 25px 50px; */
  /* margin-bottom: 60px; */
}

@media (max-width: 767px) {
  .static_cms {
    margin-bottom: 30px;
  }
}

.static_cms .box_cms {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 10px 0;
}

.static_cms img {
  width: 45px;
  flex: 0 0 45px;
  margin: 0;
  margin-right: 10px;
}

.static_cms .txt_cms {
  overflow: hidden;
  flex-grow: 1;
}

.static_cms .txt_cms h2 {
  color: #253237;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 22px;
  margin-bottom: 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .static_cms .txt_cms h2 {
    font-size: 14px;
  }
}

.static_cms .txt_cms p {
  line-height: 20px;
  color: #666;
  font-size: 13px;
  margin: 0;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .static_cms .txt_cms p {
    font-size: 11px;
  }
}

body {
  color: #ccc;
}

.footer-widget p {
  margin-bottom: 27px;
}

.footer p {
  font-family: "Nunito", sans-serif;
  font-size: 16px;
  color: rgb(145, 140, 140);
  line-height: 28px;
}

.animate-border {
  position: relative;
  display: block;
  width: 50%;
  height: 3px;
  background: #007bff;
}

.animate-border:after {
  position: absolute;
  content: "";
  width: 35px;
  height: 3px;
  left: 0;
  bottom: 0;
  border-left: 10px solid #fff;
  border-right: 10px solid #fff;
  -webkit-animation: animborder 2s linear infinite;
  animation: animborder 2s linear infinite;
}

@-webkit-keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  100% {
    -webkit-transform: translateX(113px);
    transform: translateX(113px);
  }
}

@keyframes animborder {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  100% {
    -webkit-transform: translateX(113px);
    transform: translateX(113px);
  }
}

.animate-border.border-white:after {
  border-color: #fff;
}

.animate-border.border-yellow:after {
  border-color: #f5b02e;
}

.animate-border.border-orange:after {
  border-right-color: #007bff;
  border-left-color: #007bff;
}

.animate-border.border-ash:after {
  border-right-color: #eef0ef;
  border-left-color: #eef0ef;
}

.animate-border.border-offwhite:after {
  border-right-color: #f7f9f8;
  border-left-color: #f7f9f8;
}

@keyframes primary-short {
  0% {
    width: 15%;
  }

  50% {
    width: 90%;
  }

  100% {
    width: 10%;
  }
}

@keyframes primary-long {
  0% {
    width: 80%;
  }

  50% {
    width: 0%;
  }
  100% {
    width: 80%;
  }
}

.dk-footer {
  padding: 75px 0 0;
  background-color: #003c7e;
  position: relative;
  z-index: 2;
}

.dk-footer .contact-us {
  margin-top: 0;
  margin-bottom: 30px;
  padding-left: 80px;
}

.dk-footer .contact-us .contact-info {
  margin-left: 50px;
}

.dk-footer .contact-us.contact-us-last {
  margin-left: -80px;
}

.dk-footer .contact-icon i {
  font-size: 24px;
  top: -15px;
  position: relative;
  color: #007bff;
}

.dk-footer-box-info {
  position: absolute;
  top: -122px;
  /* background: #ffffff; */
  /* box-shadow: 2px 2px 10px #575555; */
  padding: 40px 0 0;
  color: #fff;
  z-index: 2;
}

.dk-footer-box-info .footer-social-link h3 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 25px;
}

.dk-footer-box-info .footer-social-link ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dk-footer-box-info .footer-social-link li {
  display: inline-block;
}

.dk-footer-box-info .footer-social-link a i {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 40px;
  margin-right: 10px;
  color: #fff;
}

.dk-footer-box-info .footer-social-link i.ri-facebook-fill {
  /* background-color: #3b5998; */
  background-color: #4267b2 !important;
}

.dk-footer-box-info .footer-social-link i.ri-twitter-fill {
  /* background-color: #55acee; */
  background-color: #1da1f2 !important;
}

.dk-footer-box-info .footer-social-link a i.fa-google-plus {
  background-color: #dd4b39;
}

.dk-footer-box-info .footer-social-link a i.fa-linkedin {
  background-color: #0976b4;
}

.dk-footer-box-info .footer-social-link i.ri-instagram-line {
  /* background-color: #b7242a; */
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(
      #6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%
    ) !important;
}

.footer-awarad {
  margin-top: 285px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 100%;
  -moz-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-awarad p {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  margin-left: 20px;
  padding-top: 15px;
}

.footer-info-text {
  margin: 26px 0 32px;
}

.footer-left-widget {
  padding-left: 100px;
}

.footer-widget .section-heading {
  margin-bottom: 35px;
}

.footer-widget .section-heading img {
  width: 250px;
  height: 60px;
  margin-top: 20px;
}

.footer-widget h3 {
  font-size: 24px;
  color: #fff;
  position: relative;
  margin-bottom: 15px;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.footer-widget ul {
  width: 50%;
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-widget li {
  margin-bottom: 18px;
}

.footer-widget p {
  margin-bottom: 27px;
}

.footer-widget a {
  color: #fff;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  font-size: 15px;
}

.footer-widget:after {
  content: "";
  display: block;
  clear: both;
}

.dk-footer-form {
  position: relative;
}

.dk-footer-form input[type="email"] {
  padding: 14px 28px;
  border-radius: 50px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
}

.dk-footer-form input::-webkit-input-placeholder,
.dk-footer-form input::-moz-placeholder,
.dk-footer-form input:-ms-input-placeholder,
.dk-footer-form input::-ms-input-placeholder,
.dk-footer-form input::-webkit-input-placeholder {
  color: #878787;
  font-size: 14px;
}

.dk-footer-form input::-webkit-input-placeholder,
.dk-footer-form input::-moz-placeholder,
.dk-footer-form input:-ms-input-placeholder,
.dk-footer-form input::-ms-input-placeholder,
.dk-footer-form input::placeholder {
  color: #878787;
  font-size: 14px;
}

.dk-footer-form button[type="submit"] {
  position: absolute;
  top: 8px;
  right: 0;
  padding: 1px 15px;
  font-size: 22px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  border: 1px solid #007bff;
  background: #007bff;
  color: #fff;
}

.dk-footer-form button:hover {
  cursor: pointer;
}

.contact-us {
  position: relative;
  z-index: 2;
  margin-top: 65px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.contact-icon {
  position: absolute;
}

.contact-icon i {
  font-size: 36px;
  top: -5px;
  position: relative;
  color: #007bff;
}

.contact-info {
  margin-left: 75px;
  color: #fff;
}

.contact-info h3 {
  font-size: 20px;
  color: #fff;
  margin-bottom: 0;
}

.copyright {
  padding: 28px 0;
  margin-top: 0px;
  background-color: #003c7e;
  text-align: center;
  color: #fff;
}

.copyright span,
.copyright a {
  color: #fff;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.copyright a:hover {
  color: #007bff;
}

.copyright-menu ul {
  text-align: right;
  margin: 0;
}

.copyright-menu li {
  display: inline-block;
  padding-left: 20px;
}

.back-to-top {
  position: relative;
  z-index: 2;
}

.back-to-top .btn-dark {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  padding: 0;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background: #003c7e;
  border-color: #003c7e;
  display: none;
  z-index: 999;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.back-to-top .btn-dark:hover {
  cursor: pointer;
  background: #4fb68d;
  border-color: #4fb68d;
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

/* navbar-toggler */
.navbar-hamburger {
  background-color: #fff;
  color: #fff !important;
  border: 1px solid white;
  display: none !important;
}
@media (max-width: 992px) {
  .navbar-hamburger {
    display: block !important;
  }
}
@media (max-width: 991px) {
   .footer-left-widget {
    padding-left: 400px;
  }
}
.navbar-hamburger:focus {
  outline: none !important;
}
.second-nav-ul {
  text-align: center;
  padding: 0px 0;
}
.navbar-ul .nav-items {
  list-style: none;
  color: #000 !important;
  display: inline-block;
}
.second-nav-ul .nav-items a {
  color: #000 !important;
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.second-nav-ul .nav-items a:hover {
  opacity: 1;
  color: #fc0;
}
.second-nav-ul .nav-items a::before {
  transition: 100ms;
  height: 3.5px;
  content: "";
  position: absolute;
  color: #fc0;
  background-color: #4fb68d;
}

.second-nav-ul .nav-items a::before {
  width: 0%;
  bottom: 6px;
}

.second-nav-ul .nav-items a:hover::before {
  width: 68%;
}
.second-nav-ul .nav-items .cont {
  color: #000 !important;
  display: inline-block;
  position: relative;
  text-decoration: none;
}
.second-nav-ul .nav-items .cont:hover {
  opacity: 1;
}

.second-nav-ul .nav-items .cont::before {
  transition: 100ms;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #4fb68d;
}


.Your-City:focus {
  outline: none;
  border: none;
}
.heading5 {
  color: #000 !important;
}
.zipcode {
  border-radius: 5px;
  border: 1px solid rgba(175, 175, 175, 0.356);
}
.zipcode:focus {
  outline: none;
}
.close:focus {
  outline: none;
}
.banner-text {
  position: absolute;
  top: 20%;
  left: 1%;
}
.banner-text .heading2 {
  font-size: 40px;
}
@media (max-width: 992px) {
  .banner-text {
    position: absolute;
    top: 10% !important;
  }
  .heading2 {
    font-size: 30px !important;
  }
  .secbar{
    height: 50px;
    padding-bottom: 187px;
    margin-top: 12px;
    margin-bottom: -159px;
    margin-left: 0px;
  }
}

/* slider product category */

@media (max-width: 768px) {
  .carousel-inner .carousel-item > div {
    display: none;
  }
  .carousel-inner .carousel-item > div:first-child {
    display: block;
  }
}

.carousel-inner .carousel-item.active,
.carousel-inner .carousel-item-next,
.carousel-inner .carousel-item-prev {
  display: flex;
}
@media(max-width:1260){
  .secbar {
    height: auto;
    padding-bottom: 187px;
    margin-top: 12px;
    margin-right: -154px;
    /* margin-bottom: -159px; */
    /* margin-left: 150px; */
  }
}
@media(max-width:1126){
  .secbar{
    height: 50px;
    padding-bottom: 187px;
    margin-top: 12px;
    margin-bottom: -159px;
    margin-left: 220px;
    margin-right: -184px;
    
  }
}
/* display 3 */
@media (min-width: 768px) {
  .carousel-inner .carousel-item-right.active,
  .carousel-inner .carousel-item-next {
    transform: translateX(33.333%);
  }

  .carousel-inner .carousel-item-left.active,
  .carousel-inner .carousel-item-prev {
    transform: translateX(-33.333%);
  }
}

.carousel-inner .carousel-item-right,
.carousel-inner .carousel-item-left {
  transform: translateX(0);
}
.secbar {
  height: 50px;
  padding-bottom: 187px;
  margin-top: 12px;
  margin-bottom: -159px;
  margin-left: 170px;
}
.logbtn a {
  color: #a4c800;
}
.slidbtn li {
  border-radius: 50% !important;
  height: 8px !important;
  width: 8px !important;
}
.second-navbar {
  display: flex;
}
.offerbtn {
  background-color: #4fb68d;
  font-size: 13px;
  width: 15%;
  height: 34px;
  color: #fff;
  margin: 20px;
  border-radius: 5px;
  padding-top: 16px;
  padding-left: 7px;
}
.offerbtn a {
  font-size: 12px;
  border: 1px solid #fff;
  margin-left: 10px;
  padding: 2px 3px;
  border-radius: 2px;
  outline-offset: 0px;
  text-shadow: none;
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}
.offerbtn a:hover {
  border: 1px solid;
  box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.5),
    0 0 20px rgba(255, 255, 255, 0.2);
  outline-color: rgba(255, 255, 255, 0);
  outline-offset: 15px;
  text-shadow: 1px 1px 2px #427388;
}
.offerbtn i {
  padding-left: 3px;
  padding-top: 6px;
  color: #fff;
  opacity: 1;
}
.brico i {
  padding-top: 24px;
  font-size: 25px;
  color: #000;
  margin-left: 20px;
}
.shopico {
  color: #fff !important;
  font-size: 20px;
  padding-left: 13rem;
  padding-right: 10px;
}
.shophrt {
  color: #fff !important;
  font-size: 20px;
  padding-left: 51px;
}
.vl {
  border-left: 1px solid #c4c5c499;
  height: 48px;
  padding-right: 20px;
}
.pincdno {   
  border: 1px solid #ccc !important;  
  height: 40px;  /*for Pincode inputbox */
 width: 90px;
 margin-left: 12px;
 border-radius: 5px;
 margin-bottom: 16px;
 }
.pincdno:focus {
 outline: none;
 border: none;
}
.locico input[type="text"] {
  margin: 0px;
  height: 37px;
  font-size: 13px;
  border-radius: 5px 0px 0px 5px;
  border: none;
  padding-left: 28px;
  color: #e5e1e1;
}
.locico i {
  position: absolute;
  font-size: 15px;
  padding: 11px 7px;
  color: #cbc6c6;
}
.rocrd {
  transition: transform 0.2s;
}
.promid {
  padding-bottom: 98px;
  padding-top: 74px;
}
.procrd:hover {
  transform: scale(1.2);
}
.procrd img {
  line-height: 3px;
}
.procrd img {
  height: 60%;
  border-radius: 7px;
  width: 98%;
}
.procrd h4 {
  text-align: center;
  padding-top: 10px;
  color: #000;
  font-size: 20px;
}
.procrd p {
  text-align: center;
  font-size: 12px;
}
.headersearchbar{
  display: none;
}
/* .probtn button{border-radius: 3px;} */
.procrd button {
  box-shadow: 4px 3px 6px 4px #00000029;
  border: 1px solid #fff;
  display: flex;
  margin: auto;
  border-radius: 10px;
  border-color: #edf4f7;
  padding: 6px 23px;
  text-align: center;
}
.procrd button:hover {
  box-shadow: 4px 3px 6px 4px #00000029;
  border: 1px solid #ffffff;
  background-color: #4fb68d;
}
.fruimg img {
  padding-left: 15px;
  border-radius: 7px;
}
.today {
  line-height: 1.2px;
}
.today img {
  border-radius: 3px;
  width: 100% !important;
}
.tody h6 {
  padding-top: 19px;
  font-size: 14px;
  line-height: 3px;
  font-weight: 600;
}
.tody p {
  color: dimgray;
  font-size: 13px;
  margin-bottom: 0 !important;
  padding-left: 0rem;
}
.str {
  padding-left: 14px;
}
.rate p {
  float: right;
  background-color: #ff8a30;
  padding: 2px;
  font-size: 11px;
  margin-right: 14px;
  width: 26px;
  text-align: center;
  color: #fff;
}
.tody i {
  color: #ff8a30;
  text-align: center;
  line-height: 1px;
}
.hr {
  width: 96%;
  color: dimgray;
  border: 1px solid #f9f7f7;
}
.pritxt {
  padding-left: 5.6rem;
}
/* .pritxt h6{font-size: 13px;padding-left: 84px;} */
.pritxt p {
  padding-left: 12px;
}
.slidtext h5 {
  text-align: center;
  text-transform: capitalize;
  font-size: 25px;
  font-weight: 600;
  /* margin-bottom: 12.5rem !important;
  margin-left: -4.6rem; */
}
.pritxt button {
  border: 3px solid #fff;
  border-radius: 10px;
  border-color: #edf4f7;
  padding: 6px 23px;
  margin-left: -0.8rem;
  margin-top: 6px;
}
.pritxt button:hover {
  box-shadow: 4px 3px 6px 4px #dddada29;
  border: 4px solid #ffffff;
}
/* .pritxt p{font-size: 10px;padding-left: 85px;} */
.pritxtbutton {
  box-shadow: 4px 3px 6px 4px #00000029;
  border: 1px solid #fff;
  display: flex;
  margin: auto;
  border-radius: 10px;
  border-color: #edf4f7;
  padding: 6px 23px;
  text-align: center;
}
.pritxt button:hover {
  box-shadow: 4px 3px 6px 4px #00000029;
  border: 1px solid #ffffff;
  background-color: #4fb68d;
}
.catbx {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0 -2px 3px -3px #9e9e9e6e, 0 4px 3px -3px #9e9e9e6e;
  border-radius: 7px;
  opacity: 1;
}
.strrt img {
  width: 12px;
  height: 12px;
}
.gettxt h6 {
  font-size: 20px;
  font-weight: 600;
}
.gettxt p {
  font-size: 13px;
  color: #cdc3c3;
}
div.centralize {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.input-block {
  position: relative;
}

div.input-block input {

  font-weight: 500;
  color: #eef0f1;
  width: 260px;
  padding: 8px 0px;
  border-radius: 6px;
  border: 2px solid #d9d9d9;
  outline: none;
}

div.input-block span.placeholder {
  position: absolute;
  margin: 11px 0;
  padding: 0 4px;
  font-family: Roboto, sans-serif;
  color: #6c757d;
  display: flex;
  align-items: center;
  top: 0;
  left: 17px;
  transition: all 0.2s;
  transform-origin: 0% 0%;
  background: none;
  pointer-events: none;
}

div.input-block input:valid + span.placeholder,
div.input-block input:focus + span.placeholder {
  transform: scale(0.8) translateY(-30px);
  background: #fff;
}

div.input-block input:focus {
  color: #284b63;
  border-color: #284b63;
}

div.input-block input:focus + span.placeholder {
  color: #284b63;
}
/* .cust input[type="text"] {   //yaha se comment 
  margin: 0px;
  height: 45px;
  width: 200px;
  border-radius: 10px 10px 10px 10px;
  font-size: 15px;
} */
/* .plystr {
  border: 2px solid #fff;
  width: 100%;
  margin-top: 20px;
  padding: 22px 0px 0px 6px;
  border-radius: 10px;
}
.plystr img {
  width: 35%;
  padding: 17px 0px;
  margin-left: 10%;
}
.plystr p {
  color: #fff;
  text-transform: capitalize;
  line-height: 2px;
  font-size: 13px;
  text-align: center;
}
.hrply {
  width: 98%;
  border: 1px solid #cbcaca;
  
} */
/* .cust-srch {
  position: relative;
  width: 340px;
  height: 500px;
  padding-top: 22px;
}
.custom-search-input {
  width: 00%;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 0px 00px 0px 0px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  font-size: 13px;
}
.custom-search-botton {
  position: absolute;
  right: 1px;
  bottom: 1px;
  border: 0;
  background: #66cdaa;
  color: #fff;
  outline: none;
  margin: 0;
  padding: 8px 10px;
  border-radius: 3px;
  z-index: 2;
  font-size: 13px;           // yaha tak comment
} */                                
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background: #fff;
}
.mainimg span {
  color: #fff;
}
.cust {
  position: relative;
  width: 375px;
  padding-top: 0px;
}
.cust-search-input {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px 100px 10px 20px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  font-size: 13px;
}
.search-input{
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px 100px 10px 20px;
  line-height: 1;
  box-sizing: border-box;
  outline: none;
  font-size: 13px;
}
.cust-search-botton {  /* search button*/
  position: absolute;
  right: -40px;
  border: 0;
  background: #66cdaa;
  color: #fff;
  outline: none;
  margin: 0;
  padding: 0px 17px;
  border-radius: 0px 5px 5px 0px;
  z-index: 2;
  font-size: 18px;
  height: 40px;
  margin-right: 40px;
}
.search-button{
  border: 0;
  background: #66cdaa;
  color: #fff;
  outline: none;
  padding: 0px 10px;
  border-radius: 0px 5px 5px 0px;
  font-size: 15px;
  height: 35px;

}
.coolBeans {
  font-size: 13px;
  font-weight: 10px;
  box-shadow: 4px 3px 6px 4px #00000029;
  border: 3px solid;
  color: #fff !important;
  border-radius: 7px;
  padding: 7px 19px;
  position: relative;
  text-decoration: none;
  letter-spacing: 1px;
  margin-left: 5px;
  margin-bottom: 23px;
  z-index: 0;
  opacity: 1;
}

.coolBeans::after {
  box-shadow: 4px 3px 6px 4px #00000029;
  border-radius: 7px;
  border: 3px solid #ffffff;
  background-color: #4fb68d;

  z-index: -1;
}

/* .coolBeans:hover::after
    {transform: translate(0, 0);}*/

.coolBeans:hover {
  border: 3px solid #fff;
  background-color: #4fb68d;
  transform: scale(1.05);
  will-change: transform;
}
.secbanr {
  background-color: #fc0;
  width: 101%;
  height: 402px;
}
.grocery h4 {
  font-size: 50px;
  font-weight: 900;
  text-transform: capitalize;
  margin-left: -108px;
  padding-top: 30px;
}
.grocery p {
  font-weight: 900;
  font-size: 30px;
  text-transform: capitalize;
  margin-left: -108px;
}
.grocery h1{
  margin-left: -108px;
}
.cool i {
  color: #fff;
  margin-top: 6px;
  margin-right: 0px;
  margin-left: 10px;
}
.cool {
  border: 2px solid #fff;
  color: #fff;
  background-color: #66cdaa;
  border-radius: 5px;
  font-size: 21px;
  font-weight: bold;
  padding: 8px 32px;
  position: relative;
  text-decoration: none;
  margin-left: -108px;
  letter-spacing: 1px;
}
.coolbt {
  box-shadow: 4px 3px 6px 4px #00000029;
  border: 2px solid;
  white-space: nowrap;
  color: #fff !important;
  background-color: #66cdaa;
  border-radius: 7px;
  font-size: 15px;
  padding: 4px 2px;
  /* position: relative; */
  text-decoration: none;
  letter-spacing: 1px;
  margin: 4px 0px 0px 10px;
}
.coolbt:hover {
  box-shadow: 4px 3px 6px 4px #00000029;
  border-radius: 7px;
  border: 3px solid #ffffff;
  background-color: #4fb68d;
}
.procrd {
  padding-bottom: 50px;
}
.procrd button {
  box-shadow: 4px 3px 6px 4px #00000029;
  border: 1px solid #fff;
  display: flex;
  margin: auto;
  border-radius: 10px;
  border-color: #edf4f7;
  padding: 6px 23px;
  text-align: center;
}
.baneroffer {
  background: #8b0100;
  width: 101%;
}
.offfer img {
  width: 100%;
  margin-top: 20%;
  /* margin-left: -511px; */
}
.headr {
  margin: auto;
  padding-right: 67px;
}
.faq-heading {
  font-family: Lato;
  font-weight: 400;
  font-size: 19px;
  -webkit-transition: text-indent 0.2s;
  text-indent: 20px;
  color: #333;
}

.faq-text {
  font-family: Open Sans;
  font-weight: 400;
  color: #919191;
  width: 95%;
  padding-left: 20px;
  margin-bottom: 30px;
}

.faq {
  width: 1000px;
  margin: 0 auto;
  background: white;
  border-radius: 4px;
  position: relative;
  border: 1px solid #e1e1e1;
}
.faq label {
  display: block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 56px;
  padding-top: 1px;

  background-color: #fafafa;
  border-bottom: 1px solid #e1e1e1;
}

.faq input[type="checkbox"] {
  display: none;
}

.faq .faq-arrow {
  width: 9px;
  height: 9px;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border-top: 2px solid rgba(0, 0, 0, 0.33);
  border-right: 2px solid rgba(0, 0, 0, 0.33);
  float: right;
  position: relative;
  top: -30px;
  right: 27px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.faq input[type="checkbox"]:checked + label > .faq-arrow {
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.faq input[type="checkbox"]:checked + label {
  display: block;
  background: rgba(255, 255, 255, 255) !important;
  color: #4f7351;
  height: auto;
  transition: height 0.8s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.faq-heading .span {
  color: green;
  font-size: 10px;
}
.faq-text .span {
  color: red;
  font-size: 10px;
}
.faq input[type="checkbox"]:not(:checked) + label {
  display: block;
  transition: height 0.8s;
  height: 50px;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

/* ::-webkit-scrollbar {
      display: none;
    } */
/* .rounded {
  padding-top: 30px;
} */
/* Main Css */
.logimg img {
  width: 4.5rem;
  height: auto;
  margin-left: 0px;
}
.pincd {
  width: 250px;
  padding-left: 25px;
}
.shoppingbag {
}

 
@media (max-width: 991px){
  .logimg img{
    width: 4rem;
    height: auto;
    margin-left: 0px;
  }
 
}
@media (max-width: 1280px) {
  .pincd {  /* search */
    padding-left: 20px;
  }
  .secbar{
    height: 50px;
    padding-bottom: 187px;
    margin-top: 12px;
    margin-bottom: -159px;
    margin-left: 20px;
    margin-right: -130px;
  }
  .serchbtn { /* search */
    margin-left: -50px;
  }
  .shopico { /* user and cart*/
    padding-left: 2rem;
  }
  .dis-container{
    margin-right: 20px;
  }
}

@media (max-width: 1225px) {
  .pincd {  /* pincode */
    padding-right: 30px;
  }
  .secbar{
    height: 50px;
    padding-bottom: 187px;
    margin-top: 12px;
    margin-bottom: -159px;
    margin-left: 80px;
    margin-right: -130px;
  }
  .serchbtn { /* search */
    margin-left: -50px;
  }
  .shopico { /* user and cart*/
    padding-left: 5rem;
  }
  .dis-container{
    margin-right: 40px;
  }
}

@media(max-width: 1160px){
  .shopico{
    padding-left: 40px;
  }
  .secbar{
    height: 50px;
    padding-bottom: 187px;
    margin-top: 12px;
    margin-bottom: -159px;
    margin-left: 150px;
    margin-right: -130px;
  }
}

@media(max-width: 1110px){
  .pincd { /*pincode*/
    padding-left: 5px;
  }
  .secbar{
    height: 50px;
    padding-bottom: 187px;
    margin-top: 12px;
    margin-bottom: -159px;
    margin-left: 200px;
    font-size: small;
    width: fit-content;
  }

  .cust input[type="text"] { /* searchbox*/
    width: 26rem;
    margin-left: -65px;
    
  }
  .serchbtn { /* search button*/
    margin-left: 0px;
    margin-right: 0px;
  }
  .cust-search-botton{ /* search*/
    margin-right: 250px;
  }
  .shopico  { /* user and cart*/     
    margin-left: -130px;   
  }
}

@media (max-width:1026px){
  .secbar{
    height: 50px;
    padding-bottom: 187px;
    margin-top: 12px;
    margin-bottom: -159px;
    margin-left: 180px;
    font-size: small;
    width: fit-content;
  }
}

@media (max-width: 1024px) {
  .pincd {
    padding-left: -0px;    
  }
  .secbar{
    height: 50px;
    padding-bottom: 187px;
    margin-top: 12px;
    margin-bottom: -159px;
    margin-left: 195px;
    font-size: 20px;
    width:fit-content ;
  }
  .cust-search-botton{ /* search*/
    margin-right: 300px;
    margin-bottom: 50px;    
  }
  .shopico {
    padding-left: 7rem;
    padding-right: 25px;
    margin-left:-280px;
  }
  .cust input[type="text"] {
    width: 23rem;
  }
  .pritxt {
    padding-left: 2.8rem;
  }
  .serchbtn {
    margin-left: -35px;
    
  }
  .locico input[type="text"] {
    width: 135px;
  }
  .vl {
    padding-right: 21px;
  }
  .mediqr {
    padding-left: 0px !important;
  }
  .tody img {
    width: 100%;
  }
  .strrt img {
    width: 10px !important;
  }
  .centralize {
    margin-left: 0px;
    margin-right: -10px; 
  }
  .coolbt {
    margin-left: 18px;
  }
  .order_det{
    font-size: 14px;
  }  
}

@media (max-width:1021px){
  .secbar{
    height: 50px;
    padding-bottom: 187px;
    margin-top: 12px;
    margin-bottom: -159px;
    margin-left: 180px;
    font-size: small;
    width: fit-content;
  }
}
@media (max-width:1006px){
  .secbar{
    height: 50px;
    padding-bottom: 187px;
    margin-top: 12px;
    margin-bottom: -159px;
    margin-left: 175px;
    font-size: small;
    width: fit-content;
  }
}
@media (max-width:1001px){
  .secbar{
    height: 50px;
    padding-bottom: 187px;
    margin-top: 12px;
    margin-bottom: -159px;
    margin-left: 160px;
    font-size: small;
    width: fit-content;
  }
}
@media (max-width: 992px) {
  .headersearchbar{
    display: block;
    margin: auto;
    margin-left: 30px;
  }
  .pincd { /*pincode*/
    padding-left: 5px;
  }
  .cust input[type="text"] { /* searchbox*/
    width: 22rem;
    margin-left: -95px;
    
  }
  .serchbtn { /* search button*/
    margin-left: 0px;
    margin-right: 0px;
  }
  .cust-search-botton{ /* search*/
    margin-right: 360px;
  }
  .shopico  { /* user and cart*/     
    margin-left: -240px;
    padding-left: 0px;      
  } 
  .secbar{
    height: 50px;
    padding-bottom: 187px;
    margin-top: 12px;
    margin-bottom: -159px;
    margin-left: 155px;
  } 
}

@media (max-width:991px){
  .secbar {
    height: 139px;
    padding-bottom: 367px;
    margin-top: 12px;
    margin-bottom: 0px;
    margin-left:3px;
  }
  
}

@media (max-width: 768px) {
  .serchbtn {
    margin-left: 141px;
  }
  .pincd {
    padding-left: 77px;
  }
  .pritxt {
    padding-left: 16.8rem;
    padding-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .procrd {
    margin-bottom: 107px;
  }
  .locico {
    width: 109px;
  }
  .cust input[type="text"] {
    width: 17rem;
  }
  .shopico {
    padding-left: 0.4rem;
  }
  .brmenu a {
    padding-right: 30px;
  }
  .brmenui i {
    padding: 0.25rem 0.5rem;
  }
  .dropdown-menu.show {
    margin-left: -73px;
  }
  div.input-block input {
    width: 239px;
  }
  .coolbt {
    margin-left: 48px;
    margin-top: 10px;
  }
  .promid {
    padding-top: 0px;
  }
  .strrt {
    margin-bottom: -22px;
  }
  .hr {
    width: 104%;
  }
  .centralize {
    margin-left: 0px;
    margin-right: -40px;
    padding-top: 5px; 
  }
  .rate p {
    margin-right: -6px;
  }
  .pritxt {
    padding-bottom: 30px;
  }
}
@media (max-width: 640px) {
  .cust input[type="text"] {
    width: 14rem;
  }
  .hr {
    width: 103%;
  }
  .serchbtn {
    margin-left: 20px;
  }
}
@media (max-width: 540px) {
  .serchbtn {
    margin-left: 93px;
  }
  .pincd {
    padding-left: 76px;
  }
  .pritxt {
    padding-left: 9.8rem;
  }
  .rate p {
    margin-right: 3px;
  }
  .centralize {
    margin-left: 0px;
    margin-top: -10px;
    margin-right: -10px;
  }
  .coolbt {
    margin-left: 24px;
    margin-top: -2px;
  }
}
@media (max-width: 453px) {
  .headersearchbar{
    display: block;
    margin: 0px;
  }
}
@media (max-width: 414px) {
  .pritxt {
    padding-left: 4.8rem;
  }
  .serchbtn {
    margin-left: 29px;
  }
  .centralize {
    margin-left: -14px;
  }
  .rate p {
    margin-right: 5px;
  }
  .coolbt {
    margin-top: 17px;
    margin-left: 41px;
  }
  .hr {
    width: 100%;
  }
  .pincd {
    padding-left: 70px;
  }
  .hr {
    width: 100%;
  }
}
@media (max-width: 375px) {
  .serchbtn {
    margin-left: 5px;
  }
  .pritxt {
    padding-left: 3.8rem;
  }
  .centralize {
    margin-left: -31px;
  }
  .coolbt {
    margin-top: 14px;
  }
}
@media (max-width: 320px) {
  div.input-block input {
    width: 198px;
  }
  .centralize {
    margin-left: -39px;
  }
  .coolbt {
    margin-left: 3px;
  }
  .pritxt {
    padding-left: 2.8rem;
  }
  .cust input[type="text"] {
    width: 12rem;
  }
}

@media (max-width: 280px) {
  .centralize {
    margin-left: -87px;
  }
  .serchbtn {
    margin-left: -12px;
  }
  .cust input[type="text"] {
    width: 10.3rem;
  }
  .locico input[type="text"] {
    width: 87px;
  }
  div.input-block input {
    width: 170px;
  }
  div.input-block {
    margin-left: 41px;
  }
  .coolbt {
    margin-left: -36px;
  }
  .pritxt {
    padding-left: 0.8rem;
  }
  .box_cms {
    margin-left: -40px;
  }
  .static_cms img {
    margin-right: 4px;
  }
  .crd p {
    line-height: 19px;
  }
  .mediqr p {
    line-height: 19px;
  }
}

/* @media (min-width: 320px){ .pincd {padding-right: 25rem;}} */
.brmenu a {
  text-decoration: none;
  color: #000;
  font-size: 24px;
  margin-left: 66px;
}
/* marchantproduct  start*/
.marchantproduct {
  position: relative;
  margin: 70px auto;
  width: 271px;
  height: 300px;
  text-align: center;
  background: #fff;
  cursor: default;
  box-shadow: rgba(100, 100, 111, 0.2);
}
.marchantproduct h3 {
  font-size: 17px;
    margin-top: 17px;
    margin-right: 122px;
}
.marchantproduct img {
  width: 221px;
    margin-top: 20px;
    height: 180px;
}
.leftrightbtn span {
  position: absolute;
  margin-top: 20px;
  width: 35px;
  height: 35px;
  background: #003c7e;
  color: #fff !important;
  font-size: 13px;
  display: inline-block;
  line-height: 36px;
  cursor: pointer;
  border-radius: 100%;
}
.leftrightbtn i {
  color: #fff;
}
.leftrightbtn span:hover {
  background: #003c7e;
  color: #fff;
}

.leftrightbtn span.prev {
  left: -17.5px;
}

.leftrightbtn span.next {
  right: -17.5px;
}

/* .properties_qty {
  margin-top: 50px;
  height: 130px;
  font-size: 9px;
} */
.properties_qty h4 {
  font-size: 13px;
  margin-top: 42px;
}
.propertiesqty {
  display: inline-block;
  text-align: center;
  width: 50%;
  /* float: left; */
  margin-left: -56px;
}
.propertiesprices {
  color: #080808;
  font-weight: 700;
  display: inline-block;
  float: left;  
  margin-left: 27px;
  margin-top: 15px;
}
.properties_qty:before:after {
  content: " ";
  display: inline-block;
}

.properties_qty:after {
  clear: both;
}

.properties_qty .qty .fa {
  font-size: 7px;
  color: #757575;
  background: #dfdfdf;
  border-radius: 100%;
  padding: 4px;
  cursor: pointer;
}
.ip-add-cart:hover {
  background: #003c7e;
}
.ip-add-cart {
  border: none;
  padding: 7px 10px;
  background: #003c7e;
  color: #fff;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
   margin-top: 10px;
   border-radius: 25px;
  cursor: pointer;
  float: right;
    margin-right: 13px;
}

/* Start Credit Style */

.credit {
  position: absolute;
  padding: 0px 10px;
  padding-bottom: 8px;
  height: 15px;
  right: 10px;
  top: 10px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.7);
  font-size: 7px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 15px -15px rgba(0, 0, 0, 0.3);
  cursor: default;
}
.procrdpg {
  background: #ebeae7;
}
.leftarrow i {
  left: -17.5px;
}
.rightarrow i {
  right: -17.5px;
}
.credit:hover {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.credit img {
  position: relative;
  width: 14px;
  margin-left: 3px;
  top: 4px;
}

/* productdetailcards */

.card_container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  overflow: hidden;
}

.prodetail_card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 860px;
}

.shoeBackground {
  position: relative;
  width: 50%;
  background: url("../../assets/images/hotelbook.jpg");
  height: 475px;
  box-shadow: -15px 0 35px rgba(0, 0, 0, 0.1), 0 -15px 35px rgba(0, 0, 0, 0.1),
    0 15px 35px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
}

.card_gradients {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.card_gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -2;
}

.first {
  z-index: 0;
  animation: 1s width ease;
}

@keyframes width {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.card_second {
  z-index: -1;
}

.card_gradient[color="blue"] {
  background-image: linear-gradient(45deg, #0136af, #22abfa);
}

.card_gradient[color="red"] {
  background-image: linear-gradient(45deg, #d62926, #ee625f);
}

.card_gradient[color="green"] {
  background-image: linear-gradient(45deg, #11998e, #1ce669);
}

.card_gradient[color="orange"] {
  background-image: linear-gradient(45deg, #fc4a1a, #f7b733);
}

.card_gradient[color="black"] {
  background-image: linear-gradient(45deg, #111, #666);
}

.card_logo {
  position: absolute;
  width: 100px;
  left: 20px;
  top: 20px;
}

.card_share {
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  font-size: 1.3rem;
  text-decoration: none;
  color: var(--primary);
  transition: 0.5s;
}

.card_share:hover {
  transform: scale(1.1);
}

.card_share i {
  line-height: 50px;
}

.shoe {
  position: absolute;
  width: 140%;
  opacity: 0;
  bottom: 0;
  right: 0;
  transform: rotate(-20deg);
  transition: 0.5s;
}

.shoe.show {
  opacity: 1;
}

.info {
  width: 50%;
  background-color: #fff;
  z-index: 1;
  padding: 35px 40px;
  box-shadow: 15px 0 35px rgba(0, 0, 0, 0.1), 0 -15px 35px rgba(0, 0, 0, 0.1),
    0 15px 35px rgba(0, 0, 0, 0.1);
}

.shoeName {
  padding: 0 0 10px 0;
}
.vegicon i {
  color: #1e9d44;
  border: 1px solid #1e9d44;
  padding: 2px;
  font-size: 14px;
}
.shoeName div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.small {
  display: flex;
  justify-content: space-between;
}
.chnge_btn i {
  color: #000;
}
.small i {
  color: red;
}
.shoeName div .big {
  margin-right: 10px;
  font-size: 2rem;
  color: #333;
  line-height: 1;
}

.shoeName div .new {
  background-color: var(--primary);
  text-transform: uppercase;
  color: #fff;
  padding: 3px 10px;
  border-radius: 5px;
  transition: 0.5s;
}

.shoeName .small {
  font-weight: 500;
  color: #444;
  margin-top: 3px;
  text-transform: capitalize;
}

.shoeName,
.description,
.color-container,
.size-container {
  border-bottom: 1px solid #dadada;
}

.description {
  padding: 10px 0;
}

.title {
  color: #3a3a3a;
  font-weight: 600;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.text {
  color: #555;
  font-size: 17px;
}

.color-container {
  padding: 10px 0;
}

.desc_deatils {
  padding: 8px 0;
  display: flex;
  align-items: center;
}
.desc_deatils1 {
  flex: 10px;
}
.desc_deatils3 {
  margin-left: 45px;
}
.desc_deatils3 p {
  color: #ef2918;
}
.desc_deatils1 span {
  background: #417e1e;
  color: #fff !important;
  padding: 2px;
}
.size-container {
  padding: 10px 0;
  margin-bottom: 10px;
}
.chnge_dele {
  background: #fc0;
  padding: 8px 13px;
  color: #fff;
}
.chnge_continue {
  background: #fc0;
  padding: 8px 13px;
  color: #fff;
}
.chnge_update {
  background: #fc0;
  padding: 8px 13px;
  color: #fff;
}
.chnge_dele:hover {
  background: #fc0;
  transform: scale(1.1);
  color: #fff;
}
.chnge_continue:hover {
  background: #fc0;
  transform: scale(1.1);
  color: #fff;
}
.chnge_update:hover {
  background: #fc0;
  transform: scale(1.1);
  color: #fff;
}
.sizes {
  padding: 8px 0;
  display: flex;
  align-items: center;
}

.size {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: #eee;
  margin: 0 10px;
  text-align: center;
  line-height: 40px;
  font-size: 1.1rem;
  font-weight: 500;
  cursor: pointer;
  transition: 0.3s;
}

.size.active {
  background-color: var(--primary);
  color: #fff;
  transition: 0.5s;
}

.buy-price {
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  color: #333;
  display: flex;
  align-items: flex-start;
}

.price h1 {
  font-size: 2.1rem;
  font-weight: 600;
  line-height: 1;
}

.price i {
  font-size: 1.4rem;
  margin-right: 1px;
}

.buy {
  padding: 0.7rem 1rem;
  background-color: #007bff;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 1.1rem;
  transition: 0.5s;
}

.buy:hover {
  transform: scale(1.1);
}

.buy i {
  margin-right: 2px;
}

@media (max-width: 1070px) {
  .shoe {
    width: 135%;
  }
}

@media (max-width: 1000px) {
  .procard {
    flex-direction: column;
    width: 100%;
    box-shadow: 0 0 35px 1px rgba(0, 0, 0, 0.2);
  }

  .procard > div {
    width: 100%;
    box-shadow: none;
  }

  .shoe {
    width: 100%;
    transform: rotate(-5deg) translateY(-50%);
    top: 55%;
    right: 2%;
  }

  .procard {
    top: 20%;
    left: 5%;
  }
}

@media (max-width: 600px) {
  .card_share {
    width: 40px;
    height: 40px;
  }

  .card_share i {
    font-size: 1rem;
    line-height: 40px;
  }

  .card_logo {
    width: 70px;
  }
}

@media (max-width: 490px) {
  .procard {
    font-size: 7rem;
  }

  .shoeName div .big {
    font-size: 1.3rem;
  }

  .small {
    font-size: 1rem;
  }

  .title {
    font-size: 0.9rem;
  }

  .text {
    font-size: 0.8rem;
  }

  .buy {
    padding: 0.5rem 0.8rem;
    font-size: 0.8rem;
  }

  .price h1 {
    font-size: 1.5rem;
  }

  .price i {
    font-size: 0.9rem;
  }

  .size {
    width: 30px;
    height: 30px;
    margin: 0 8px;
    font-size: 0.9rem;
    line-height: 30px;
  }

  .color {
    margin: 0 6px;
    width: 0 20px;
    width: 20px;
    height: 20px;
    border-width: 4px;
  }

  .card_share {
    width: 35px;
    height: 35px;
    top: 10px;
    right: 10px;
  }

  .card_share i {
    font-size: 0.8rem;
    line-height: 35px;
  }

  .card_logo {
    width: 60px;
    top: 10px;
    left: 10px;
  }

  .info {
    padding: 20px;
  }
}

@media (max-width: 400px) {
  .buy i {
    display: none;
  }

  .container {
    padding: 20px;
  }
}
#AddressElemet-1{
  margin-top: 10px;
}
#AddressElemet-3{
  margin-left: 10px;
  height: 40px;
  width: 215px;
  padding: 15px;
}
#AddressElemet-2{
   height: 40px;
  width: 215px;
  margin-top: 10px;
  padding: 15px;
}
#AddressElemet-4{
margin-right: 15px;
margin-top: 10px;
}
#AddressElemet-5{
  margin-right: 15px;
  margin-top: 10px;
}

#AddressElemet-8{
  margin-top: 10px;
}
#AddressElemet-9{
  margin-top: 10px;
}
#AddressElemet-10{
  margin-top: 10px;
  height: 40px;
  width: 440px;
  padding: 15px;
}
#AddressElemet-11{
  margin-top: 10px;   
}
#AddressElemet-13{
  margin-top: 10px;
  height: 40px;
  width: 440px;  
  padding: 15px; 
}
#genderbox{
  margin-left: 10px;
}
#addressButton{
  width: 440px;
  margin-left: 24px;
  margin-bottom: 20px;
}
#AddressElemet-14{
  margin-left: 10px;
}
#profile_a{
  color: #0a58ca;
display: flex;
justify-content: center;
margin-top: -29px;
margin-left: -27px;
}
.scrolleffect::-webkit-scrollbar {
  width: 8px !important;
  height: 5px !important;
}

.scrolleffect::-webkit-scrollbar-thumb {
  background: #d6d6d6 !important;
  border-radius: 20px !important;
  margin-left: "20em" !important;
}

.scrolleffect::-webkit-scrollbar-track {
  border-radius: 5px;
  margin-left: "20em" !important;
}

.scrolleffect::-webkit-scrollbar-thumb:hover {
  background: #d6d6d6 !important;
  margin-left: "20em" !important;
}
.text-selected {
  border-left: 5px solid #003C7E;
  color: #003C7E;
}