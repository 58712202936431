.Privacy_policy_container h4,
.Privacy_policy_container h5,
.Privacy_policy_container h6,
.Privacy_policy_container small {
	font-family: "Roboto", sans-serif;
}

.Privacy_policy_bolded_content {
	font-family: "Roboto", sans-serif;
}

.Privacy_policy_subheading_text {
	font-weight: 600;
}
.bs {
	box-shadow: none !important;
}
.iner-point {
	margin-left: 20px;
}

@media (min-width: 450px) and (max-width: 500px) {
	.Privacy_policy_container {
      width: 105% !important;
	  margin: 0 20px !important;
	}
	.bs {
		box-shadow: none !important;
	}
}

@media (min-width: 400px) and (max-width: 450px) {
	.Privacy_policy_container {
      width: 120% !important;
	  margin: 0 20px !important;
	}
	.bs {
		box-shadow: none !important;
	}
}
@media (min-width: 350px) and (max-width: 400px) {
	.Privacy_policy_container {
      width: 135% !important;
	  margin: 0 20px !important;
	}
	.bs {
		box-shadow: none !important;
	}
}

@media (min-width: 320px) and (max-width: 350px) {
	.Privacy_policy_container {
      width: 155% !important;
	  margin: 0 20px !important;
	}
	.bs {
		box-shadow: none !important;
	}
}

