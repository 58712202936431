.main-cart-container {
    margin-top: 80px;
    padding: 40px 0;
}
.small-container {
    display: flex;
    justify-content: space-between;
}
.empty-container {
    display: grid;
    place-items: center;
}
.empty-cart-icon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: grid;
    place-items: center;
    /* background-color: #e63232; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.empty-cart-icon img {
    width: 75px;
    height: 75px;
    text-align: center;
}
.heading-cart {
  font-size: 22px;
  font-weight: 600;
  margin: 20px 0 10px 0;
  color: #000;
}
.para-cart {
    font-size: 15px;
    font-weight: 400;
    margin: 5px 0 20px 0;
    color: rgba(128, 128, 128, 0.712);
    padding: 0 10px;
}
.btn-cart {
  border: none !important;
  font-size: 17px !important;
  background-color: #003B95 !important;
  color: #fff !important;
  padding: 10px 35px;
}
.cart-page{
    margin:20px auto;
    
    }
    table{
        width:100%;
        border-collapse: collapse;
    }
    .cart-info{
        display: flex;
        flex-wrap:wrap;
    }
    th{
        text-align: left;
        padding:5px;
        color:#fff;
        background: #003C7E;
        font-weight: normal;
    }
    tr{
        
        /* box-shadow: rgba(0, 0, 0, 0.05) 0px 25px 20px -20px; */
        /* box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 50px; */
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }
    td{
        padding:10px 15px 10px 10px;
        font-size: 0.84rem;
    
    
    }
    .res{
    margin-right: auto;
    padding-left: 30px ;
    
    }
    
    td h6{
        padding-top: 5px;
        font-size:1rem;
    }
    td input{
       width:40px;
       height:30px;
       padding:5px;
    }
    td img{
        width:50px;
        height:50px;
        margin-right: 5px;
        margin-top: 5px;
    
    }
    
    .total-price{
        display:flex;
        justify-content: flex-end;
    
    }
    .total-price table{
        /* border-top: 3px solid #003C7E; */
        border-top: 3px solid #003B95;
        width:630px;    max-width:1000px;
    }
    
    .total-price table tr{
     box-shadow: none;
    
    
    }
    .total-price table tr td{
      
        padding:9px 15px;
        font-size: 0.8rem;
        font-weight: bold;
       }
    td:last-child{
        text-align: right;
        font-weight: bold;
    }
    th:last-child{
        text-align: right;
    }
    
    
    
    .rmv{
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        background-color:#003C7E; 
        color:white;
        }
    
    .delete{
     color:#003C7E; 
     background: transparent;
    font-weight: bold;
    margin: 1rem auto;
    
    
    }
    
    .proceed{
        background-color:#003C7E; 
        color:white;
        
    }
    
    @media screen and (max-width: 600px) {
        td h6{
            display: none;
        }
       
        
       .rmv{
        margin-top: 2rem;
       }
       .res{
        margin:0;
        padding-top: 6px;
        font-weight: bold;
        padding-left: 1px;
        }
    
    }
    
    .order-total-price  tr{
        box-shadow: none;
       
       
       }
      .grand{
        border-top: 1px solid #4d4d4d;
        border-bottom: 2px solid #4d4d4d;
       }
    .text-cl-bl{
        color:#003C7E
    }
    
    .table-data-cart {
        width: 62%;
        border-top: 3px solid #003B95;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        
    }
    .total-price {
        width: 36%;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        
    }
    .table-total-price tr td{
        margin: 10px;
    }
    .TABLE-ROW-FORcTRT {
        box-shadow: none;
        padding: 100px !important;
    }
    @media (max-width: 768px) {
        .small-container {
          flex-direction: column;
        }
        .table-data-cart {
            width: 100%;   
            margin-bottom: 25px;
        }
        .total-price {
            width: 100%;   
        }
      }