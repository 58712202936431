input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container-shipping {
  margin-top: 80px;
  padding: 50px;
  display: flex;
  justify-content: center;
}
.inner-container-address {
  width: 80% !important;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.address-Container {
  width: 80%;
   display: flex;
   justify-content: space-between;
   align-items: center;
}
.img-address-contaienr {
  width: 50%;
  text-align: center;
}
.img-address-contaienr img {
  width: 90%;
}
.form-address-contaienr {
  width: 48%;
}
.borderLess {
  border: none !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.Delivery-Address-header {
  padding: 15px 25px;
}
.Delivery-Address-header h2 {
  margin-bottom: 10px;
  padding-bottom: 15px;
  font-size: 25px;
  font-weight: 700;
  border-bottom: 1px solid rgba(128, 128, 128, 0.268);
}
.fomr-lable {
  margin-bottom: 0px !important;
}
.btn-color {
  background-color: #003B95 !important;
  color: #fff !important;
}
.btn-color:hover {
  background-color: #fff !important;
  color: #003B95 !important;
  cursor: pointer;
}
@media (max-width: 992px) {
  .img-address-contaienr img {
    width: 100%;
  }
  .inner-container-address {
    width: 90% !important;
  }
}
@media (max-width: 768px) {
  .img-address-contaienr {
    display: none !important;
  }
  .form-address-contaienr {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .inner-container-address {
    width: 100% !important;
  }
}