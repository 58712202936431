.merchant-carts {
    width: 100% !important;
}
.merchant-cart {
    cursor: pointer;
    width: 100%;
}
.img-container {
    overflow: hidden;
}
/* .merchant-cart img {
    transition: 1s;
}
.merchant-cart img:hover {
    transform: scale(1.3);
    z-index: 2;
} */

.single-cart {
    margin: 20px 0;
    transition: 1s;
}
.merchant-cart:hover {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    z-index: 2;
}

.single-cart:hover{
    transform: scale(1.2);
} 
.owl-dots {
    display: none !important;
}
.border-l-none {
    border-left: none !important;
}
.static_cms {
    background-color: aliceblue;
}
.txt_cms h2 {
    color: #003B95 !important;
}
.bookYourRide {
    /* border-radius: 10px; */
    width: 100%;
    height: 200px;
    background-size: cover !important;
    margin-bottom: 25px;
} 
/* #bannerImg2 {
    margin-left: 2%;
} */
.border-left {
    border-left: none !important;
    margin-top: 100px !important;
}

.banner-imag-with-sliders {
    /* width: 100%; */
    height: 100%;
    margin-top: 20px;
    /* border: 1px solid red; */
}

.inner-container-baner-rides {
    width: 49.5%;
}

.ride-bannner {
    width: 100%;
    position: relative;
}

.ride-bannner-img {
    width: 100%;
    height: 250px;
}
.img5 {
    width: 100%;
    background-size: cover;
    background-position: center;
    height: 100%;
} 
.main-ride-hotel-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.ride-bannner-text-TEXT {
    width: 80%;
    position: absolute;
    top: 0;
    transform: translate(13%,28%);
    /* margin: 7%  !important; */
    background-color: rgba(60, 64, 67, 0.6);
    padding: 10px;
}
.ride-bannner-text-TEXT h3, .ride-bannner-text-TEXT h4 {
    text-align: center;
    color: aliceblue !important;
}
.ride-bannner-text-a {
    margin-left: 37% !important;
}

.Categories-container {
    margin-left: 35px;
    margin-right: 35px;
}

@media (min-width: 320px) and (max-width: 680px){
    .ride-bannner {
        height: 30px;
        margin-bottom: 30px;
    }
    .ride-bannner .ride-bannner-img img {
        height: 100px;
        /* border: 1px solid red; */
    }
    .ride-bannner-text-TEXT {
        height: 50px;
        transform: translate(10%, 20%);
        /* border: 1px solid red; */
    }
    .ride-bannner-text-TEXT h3,.ride-bannner-text-TEXT h4, .ride-bannner-text-TEXT a  {
        font-size: 10px !important;
    }
    .ride-bannner-text-TEXT .ride-bannner-text-a {
        margin-left: 20px !important;
        font-size: 8px !important;
    }
}