@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif
}

body {
    background-color: #7C4135
}
nav .logo a {
    color: #000;
    font-size: 1.2rem;
    font-weight: bold;
    text-decoration: none
}

nav div.ml-auto a {
    text-decoration: none;
    font-weight: 600;
    font-size: 0.8rem
}
nav,
.wrapper {
    padding: 10px 50px
}

#order-heading {
    background-color: #edf4f7;
    position: relative;
    border-top-left-radius: 25px;
    max-width: 800px;
    padding-top: 20px;
    margin: 30px auto 0px
}

#order-heading .text-uppercase {
    font-size: 0.9rem;
    color: #777;
    font-weight: 600
}

#order-heading .h4 {
    font-weight: 600
}

#order-heading .h4+div p {
    font-size: 0.8rem;
    color: #777
}

.close {
    padding: 10px 15px;
    background-color: #777;
    border-radius: 50%;
    position: absolute;
    right: -15px;
    top: -20px
}

.wrapper {
    padding: 0px 50px 50px;
    max-width: 800px;
    margin: 0px auto 40px;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px
}

.table th {
    border-top: none
}

.table thead tr.text-uppercase th {
    font-size: 0.8rem;
    padding-left: 0px;
    padding-right: 0px
}

.table tbody tr th,
.table tbody tr td {
    font-size: 0.9rem;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 5px
}

.table-responsive {
    height: 100px
}

.list div b {
    font-size: 0.8rem
}

.list .order-item {
    font-weight: 600;
    color: #6db3ec
}

.list:hover {
    background-color: #f4f4f4;
    cursor: pointer;
    border-radius: 5px
}

label {
    margin-bottom: 0;
    padding: 0;
    font-weight: 900
}

button.btn {
    font-size: 0.9rem;
    background-color: #4FB68D;
    outline: 2px solid;
    text-decoration: none;
    border-radius: 8px;}

button.btn:hover {
    background-color: #4FB68D;
    outline: 2px solid;
    text-decoration: none;
    border-radius: 8px;}

    .btn-success{background-color: #4FB68D;
        outline: 2px solid;
        text-decoration: none;
        border-radius: 8px;}
        .btn-success:hover{ outline: 2px solid;
            text-decoration: none;
            border-radius: 8px;}

.price {
    color: #5cb99a;
    font-weight: 700
}

p.text-justify {
    font-size: 0.9rem;
    margin: 0
}

.row {
    margin: 0px
}

.subscriptions {
    border: 1px solid #ddd;
    border-left: 5px solid #ffa500;
    padding: 10px
}

.subscriptions div {
    font-size: 0.9rem
}

@media(max-width: 425px) {
    .wrapper {
        padding: 20px
    }

    body {
        font-size: 0.85rem
    }

    .subscriptions div {
        padding-left: 5px
    }

    img+label {
        font-size: 0.75rem
    }
}
/* .offerbtn{background-color:#4FB68D;font-size: 13px;
    width: 17%;}
.offerbtn a{padding-left: 10px;
            font-size: 11px;}
            .offerbtn i{padding-left: 3px;padding-top: 3px;} */

            .procrd h2{text-align: center;padding: 20px;color: #000;font-size: 20px;}

.carousel-inner > .item > img {
  width:640px;
  height:360px;
}

