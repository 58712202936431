.order-details{
    display: flex;
    align-items:column;
    justify-content: center;
}
.order-info-details {
    margin: 10px 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.order-summary-cartt {
    box-shadow: none;
}
.order-summary-cart td {
    width: 33%;
    /* border: 1px solid red; */
}
.order-summary-cart-img {
    width: 50%;
    height: 50% !important;
    border-radius: 5px;
}
.order-total-price-catr {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-top: 20px;
    padding: 10px 20px 30px 20px !important;

}
.stepper-main-sec {
    margin-top: 110px !important;
}
.order-total-price-catr tr {
    box-shadow: none;
    padding: 20px !important;
}