.main-francise-container {
    margin-top: 80px;
    padding: 40px 0 35px 0;
    background-color: aliceblue;
}
label + .MuiInput-formControl {
    width: 100% !important;
}
.franchise-submit{
    padding-top: 30px;
}
.franchise-container{
    display: flex;
  /* align-items: center; */
  justify-content: center;
  }
  .distributor-container-franchise {
    padding-left: 55px;
  }
  .francide-container {
    /* border: 1px solid red; */
  }
  .innner-francide-container {
    /* width: 50%; */
  }
  .innner-francide-container img {
    width: 600px;
    height: 440px;
  }