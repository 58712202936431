.main-contact-section {
  margin-top: 80px;
  padding: 40px 0 35px 0;
  background-color: aliceblue;
}
.MuiFormControl-root {
    display: flex !important;
    padding-bottom: 0px !important;
  }
  .MuiFormControl-root:focus {
    padding-bottom: 20px;
  }
  .company_Bg img {
    width: 100%;
  }
  form {
    /* margin: auto; */
    width: 322px;
  }
  .contact_details {
    margin-bottom: 24px;
    margin-top: 46px;
  }
  
  .company_det {
    margin: 40px 0px;
  }
  textarea {
    background-color: transparent;
    /* margin-top: 25px; */
    padding: 0px 13px;
  }
  .MuiButton-contained {
    color: #fff !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0  20%),
      0px 2px 2px 0px rgb(0 0 0  14%), 0px 1px 5px 0px rgb(0 0 0  12%) !important;
    background-color: #003c7e !important;
    display: flex !important;
    margin: auto !important;
  }
  .contact_Email {
    font-size: 25px;
    padding: 0px 0px;
  }
  .contact_email {
    height: 90px;
    margin-left: 30px;
  }
  .Company-container{
    display: flex;
    /* align-items: center; */
    justify-content: center;
  }
  .contact_form_container{
    position: relative;
    width:90%;
    height:98%;
    overflow: hidden;
    padding-top: 56.25%;
    border-radius: 25px;
    display: block;
    margin: auto;
  }
  .responsive-iframe{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  @media (min-width: 320px) and (max-width: 768px) {
    .ifrmeMap_section {
      display: none;
    }
    .inner-distributor-container {
      display: block !important;
    }
    .inner-distributor-container-inner-distributor-container {
      display: block;
      width: 100%;
    }
  }