.pass_show{position: relative} 

.pass_show .ptxt { 

position: absolute; 

top: 50%; 

right: 10px; 

z-index: 1; 

color: #f36c01; 

margin-top: -10px; 

cursor: pointer; 

transition: .3s ease all; 

} 

.pass_show .ptxt:hover{color: #333333;} 

body {
    margin: 0;
    /* padding-top: 40px; */
    color: #2e323c;
    background: #f5f6fa;
    position: relative;
    height: 100%;
}
.col-xl-6.col-lg-6.col-md-6.col-sm-6.col-12{
    margin-top: 20px;
}
.btn.btn-primary.btn-sm{
    font-size: 8px;
}
.account-settings .user-profile {
    margin: 0 0 1rem 0;
    padding-bottom: 1rem;
    text-align: center;
}
.account-settings .user-profile .user-avatar {
    margin: 0 0 1rem 0;
}
.account-settings .user-profile .user-avatar img {
    width: 150px;
    height: 150px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
}
.account-settings .user-profile h5.user-name {
    margin: 0 0 0.5rem 0;
}
.account-settings .user-profile p.user-email {
    margin: 0;
    /* font-size: 0.8rem; */
    font-weight: 400;
    color: #9fa8b9;
    font-size: 13px;
}
.account-settings .user-profile p.user-number {
    margin: 0;
    /* font-size: 0.8rem; */
    font-weight: 400;
    color: #8c919b;
    font-size: 13px;
}
.form-control {
    border: 1px solid #cfd1d8;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    font-size: .825rem;
    background: #ffffff;
    color: #2e323c;
    margin-top: 8px;
}
.card {
    background: #ffffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 0;
    margin-bottom: 1rem;
}
