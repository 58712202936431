.main-container-notified {
    margin-top: 80px;
    padding: 30px 0;
}
.notification-sec {
    display: flex;
    display: grid;
    place-items: center;
}
.inner-sec-noti {
    width: 70%;
    /* border: 1px solid red; */
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.heder-box {
  border-bottom: 1px solid rgba(128, 128, 128, 0.277);
}
.heder-box h1 {
    font-size: 22px;
    color: #003B95;
}
.up-noti-catr {
    display: flex;
    justify-content: start;
    /* border: 1px solid red; */

}
.cart-noti-img {
    width: 60px;
    /* border: 1px solid red; */

}
.cart-noti-img img {
    width: 100%;
}
.inner-cart-noti {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
}
.right-para p {
    font-size: 18px;
    color: #003B95;
    cursor: pointer;
}