.Order-sum{
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    margin-top:20px;
    margin-bottom:30px;
    /* margin-left: 40px; */
}


.butt{
    /* margin-left: -310px; */
    margin-left: 30px;
}

.dropdown{
    display: flex;
    margin-left:300px;
    margin-bottom:50px
    
}
.Text{
    margin-left:100px !important;
    width: 300px !important;
    height:50px !important;
    
}
.cord{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}
.Content{
    display: flex;
    background-color:#F0F2F2;
}
.Content2{
    display: flex;
    margin-top: -44px;
    background-color:#F0F2F2;

}

hr.new1 {
  border-top: 1px solid gray;
  margin-top: -2px
}

.delivery{
    display: flex;
    justify-content: space-between;
}
.hr.new2 {
  border-top: 1px solid red;
  
}
.MuiOutlinedInput-inputMarginDense{
    padding-top:2px !important;
    padding-bottom:6px !important;
}
.MuiOutlinedInput-input{
    
    padding-bottom:4px !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline{
    top:5px !important;
}
.Tabs{
    display: flex;
    margin-left: 20%;
}