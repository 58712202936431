.Distributor-main-container {
  margin-top: 80px;
  background-color: aliceblue;
  padding: 40px 0 35px 0;
}
.MuiFormControl-root {
    display: flex !important;
  }
  .MuiFormControl-root:focus {
    padding-bottom: 20px;
  }
  .company_Bg img {
    width: 100%;
  }
  form {
    /* margin: auto; */
    width: 322px;
  }
  .contact_details {
    margin-bottom: 24px;
    margin-top: 46px;
  }
  
  .company_det {
    width: 100%;
    padding: 0 30px;
    margin: 40px 0 0 0px;
    /* border: 1px solid #ccc; */
  }
  textarea {
    background-color: transparent;
    /* margin-top: 25px; */
    padding: 0px 13px;
  }
  .MuiButton-contained {
    color: #fff !important;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0  20%),
      0px 2px 2px 0px rgb(0 0 0  14%), 0px 1px 5px 0px rgb(0 0 0  12%) !important;
    background-color: #003c7e !important;
    display: flex !important;
    margin: auto !important;
  }
  .contact_Email {
    /* font-size: 25px; */
    font-size: 50px;
    padding: 0px 0px;
  }
  .contact_details-row {
    padding: 0 20px;
  }
  .contact_email {
    height: 90px;
    margin-left: 30px;
    padding-top: 15px;
    
  }
  .distributor-container{
    display: flex;
    width: 100%;
    /* align-items: center; */
    justify-content: center;
    border: none !important;
  }
  .contact_form_container{
    position: relative;
    width:100%;
    height:98%;
    overflow: hidden;
    padding-top: 56.25%;
    border-radius: 25px;
    display: block;
    margin: auto;
  }
  .responsive-iframe{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  /*  */
  /* .fa-envelope{
    background: red;
    -webkit-background-clip: text;
    background-clip: text;
    color: red;
    -webkit-text-fill-color: transparent;
  } */

  .inner-distributor-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;

  }
  /* .distributor-container {
    padding-right: 35px;

  } */