.frm-bs
{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  /* box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px; */
}

.navbar{
  /* background-color: #003c7e; */
  background-color: #003B95;
  height: 80px !important;
  
}

.bg-light {
  --bs-bg-opacity: 0 !important;
  background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity))!important;
}
.navbar-toggler{
  color: #fff;
  background-color: #074175;
  border: 1px solid #074175;
}
.navbar-toggler-icon{
  font-size: 18px; margin-right: 5px
}
.nav-link text-white{
  display: flex; margin: auto; padding-bottom: 0px
}
.fa fa-map-marker{
  color: #fff; font: size 17px; padding: 3px
}
.fa fa-shopping-cart text-white{
  color: #fff
}
/* .btn{
  background-color: #ee5443; color: #fff
} */
/* .btn-space{
  margin-right: 7px;
} */
button.btn-danger{
  background-color:red !important ;
}
.nav-link1{
  font-weight: 700; font-size: 15px;
  color: #000;
}
.dropdown-toggle{
  font-weight: 700; font-size: 15px; padding-top: 8px
}
.img1{
  opacity: 1.4;z-index: -1;
}
.sr-only{
  color: #fff;
}
.fa fa-angle-left{
  font-size: 1.73em;color: #f7f6f6;
}
.carousel-control-prev{
  color: #fff;
}
.carousel-control-next{
  color: #fff;
}
.fa fa-angle-right{
  font-size: 1.73em;color: #f7f6f6;
}
.p1{
  color: gray;line-height:12px;
}
.img5{
  height: 390px;
}
.img6{
  padding-top: 5px;height: 399px;
}
.h4{
  color:#66cdaa;
}
.p12{
  color: gray; height: 18px;line-height: 12px;
}
.text-decoration-none{
  font-size: 17px; font-family: 'Open Sans', sans-serif;
}
.cool{
  color:#fff
}
.h44{
  color:#66cdaa;
}
.p123{
color: gray; height: 18px;line-height: 12px;

}
.h43{
  color:#66cdaa;
}
.p1234{
  color: gray; height: 18px;line-height: 12px;
}
.img7{
  width: 50%;height: 366px;
}
.p5{
  color: gray; height: 18px;line-height: 12px;
}
.h24{
  color:#66cdaa;
}.img8{
  width: 100%;height: 367px;
}
.dk-footer{
  background-color: #003c7e !important
}.dk-footer-box-info{
  margin-left: 40px; margin-top: 80px
}.mt-2{
  width: 90px; height: 90px
}
.plystr{
  border: 2px solid #fff;
  width: 80%;
  padding: 22px 0px 0px 6px;
  border-radius: 10px;
  
}
.btn btn-dark {
  display: block
}
.block{
  font-size: 20px
}
.h9{
  font-size: 18px
}.a4{
  font-size: 14px
}.icon{
  font-size: 25px
}
.form-control{
  width: 100%; height: 150px
}
.img51{
  width: 98%; height: 70%
}