.main-profile-container {
    margin-top: 80px;
    display: grid;
    /* place-items: center; */
}
.inner-profile-container {
    width: 100%;
}
.profile-inner-contaienr {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    border: none !important;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    padding: 10px;
    /* position: relative; */
}
.borderremove {
    border: none !important;
}
.left-img-section {
    width: 27%;
    /* position: absolute; */
    /* border: 1px solid red; */
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.top-left-bar-profile {
    display: flex;
    align-items: center;
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    border-bottom: 1px solid rgba(128, 128, 128, 0.321);
    padding: 20px 30px;
}
.top-left-bar-profile div{
    margin-left: 10px;
}
.top-left-bar-profile h1 {
    font-size: 25px;
    font-weight: 600;
}
.menuOptions {
    padding: 15px 30px;
}
.menuOptions h1 {
    font-size: 18px;
    font-weight: 500;
    cursor: pointer;
    color: #000;
}
.menuOptions h1:hover {
    color: #003B95;
}

.top-left-bar-profile img {
    width: 100px !important;

}
.left-img-section .next-container-profile {
    /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
    /* margin-top: 30px; */
    padding: 20px 10px;
}
.right-side-secton {
    width: 71%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    padding: 20px 0;
}
.profilemenusActive h1  {
    color: #003B95;
}
.left-img-section img {
    width: 100%;
}
.headerProfile {
    color: #003B95;
    margin-left: 29px;
    font-size: 30px;
}
.updatebtn {
    background-color: #003B95 !important;
    border: none !important;
    color: #fff !important;
    padding: 10px 20px !important;
    font-size: 16px !important;
    border-radius: 5px !important;
    width: auto !important;
    margin-left: 12px !important;
}
.updatebtn:hover {
    color: #003B95 !important;
    background-color: #fff !important;
}
.profilemenusActive h2  {
    color: #003B95 !important;
}
.next-container-profile .menuOptions h2 {
    display: none; 
}
.inner-accountDelete-bar {
    border: 1px solid #E89910;
    padding: 20px 10px;
    border-radius: 4px;
    background-color: #FEF7E9;
}
.accountDelete-bar {
    padding: 10px 20px;
}
.inner-accountDelete-bar h2 {
    color: #000;
    font-size: 19px;
    font-weight: 600;
}
.inner-accountDelete-bar p {
    color: #000;
    font-size: 15px;
}
.leaving-us {
    margin-top: 30px;
}
.leaving-us .textAra {
   border: 1px solid gray;
   width: 100%;
   padding-top: 10px;
}
.delete-btn {
    background-color: #d23c33;
    color: #fff;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 10px;
}
.delete-btn:hover {
    background-color: #d23b33c2;
}
.login-bar-delete-account {
    padding: 10px 20px;
}
.inner-login-bar-delete-account {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}
.inpus {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;

}
@media (max-width: 1130px) { 
    .left-img-section {
        width: 35%;
    }
    .right-side-secton {
        width: 63%;
    }
    .menuOptions {
        padding: 10px;
    }
    .menuOptions h1 {
        font-size: 15px;
    }
}
@media (max-width: 891px) { 
    .top-left-bar-profile {
        padding: 20px 10px;
    }
    .top-left-bar-profile h1 {
        font-size: 20px;
        font-weight: 600;
    }
    .menuOptions {
        padding: 10px 5px;
    }
    .menuOptions h1 {
        font-size: 12px;
    }
}
@media (max-width: 768px) {
    .top-left-bar-profile {
        display: none; 
    }
    /* .next-container-profile {
        padding: 20px 0 0 0px !important;
    } */
    .next-container-profile .menuOptions h1 {
        display: none; 
    }
    .next-container-profile .menuOptions h2 {
        display: block;  
    }
    .left-img-section {
       width: 15%;
    }
    .right-side-secton {
        width: 84%;
    }
    .profile-inner-contaienr {
        box-shadow: none;
    }
}
@media (max-width: 500px) {
    .next-container-profile {
        padding: 20px 0 0 0px !important;
    }
    .menuOptions h2 {
        font-size: 18px;
        font-weight: 600;
    }
    .left-img-section {
        width: 15%;
     }
     .right-side-secton {
         width: 84%;
     }
     .inner-profile-container {
        padding: 5px !important;
     }
}