@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,500&display=swap');


.imagehover:hover {
	width: 100%;
}
.grocery-div {
	padding: 30px 0px;
	font-size: 20px;
}

.product-card {
	margin: auto;
}
/* .card-img-top{
  
    align-items: stretch;
} */
.card-img-top {
	height: 100px;
	width: 100px;
	margin: auto;
}
.bs {
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
	/* box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}

.shipcard {
	margin: 0 100px 0 350px;
}
